import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App.js";

import "bootstrap/dist/css/bootstrap.css";
import "./style.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID,
  // measurementId: process.env.REACT_APP_MEASUREMENT_ID,

  apiKey: "AIzaSyA4sWKhGA5TEHATJOWGHtIfZzncEL50jgI",
  authDomain: "leah--jia.firebaseapp.com",
  databaseURL: "https://leah--jia-default-rtdb.firebaseio.com",
  projectId: "leah--jia",
  storageBucket: "leah--jia.appspot.com",
  messagingSenderId: "1044760998504",
  appId: "1:1044760998504:web:49c58ce2ca37f7975101dd",
  measurementId: "G-LZBYYSZF5R"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
